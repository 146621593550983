<template>
  <Box>
    
    <SavingDialog 
      v-show="showSavingDialog" 
      @close="closeSavingDialog" />
    
    <ErrorRow :error="error" />
    <SuccessRow :message="message" />
    
    <Row>
      <Column>
        <VehicleEdit
          :defaultValue="vehicle"
          :disableAll="disableAll"
          :checkRequirements="requirementsCheck"
          :isEdit="true"
          v-on:enter="done"
          v-on:value="setValue"
          v-on:save="save"
          />
      </Column>
    </Row>
  
      
    <Row>
      <Column :width="5"/>
      <Column :width="5">
        <Box>
          <SaveRow :disabled="false" 
            v-on:value="checkRequirements" 
            label="ACTION_SAVE_CLOSE" />
        </Box>
      </Column>
      <Column :width="5"/> 
    </Row>
  
    <SpacerRow />
    
  </Box>
</template>

<script>
import { mapGetters } from 'vuex';
import VehicleEdit from '@/views/portal/vehicles/edit/VehicleEdit.vue';

import StringUtils  from '@/utils/StringUtils.js';
import EventUtils   from '@/utils/EventUtils.js';
import ConstUtils   from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';

import ErrorRow from '@/components/row/ErrorRow.vue';
import SaveRow from '@/components/row/SaveRow.vue';
import SuccessRow from '@/components/row/SuccessRow.vue';

import Vehicle from '@/domain/model/vehicle/Vehicle.js';

import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";
import SpacerRow from "@/portals/shared/library/spacer/SpacerRow.vue";
import SavingDialog from "@/portals/shared/views/saving/SavingDialog.vue";

export default {
  name: 'portal-edit',
  components: {
    VehicleEdit, SavingDialog,
    ErrorRow,
    SaveRow,
    SuccessRow,
    SpacerRow,
    Box,Row, Column,
  },
  data() {
    return {
      id: this.$route.params['id'], 
      closeAfterSaving: false,
      kvps: {},
      event: {},
      userData: {},
      message: null,
      error: null,
      isSaveDisabled: true,
      isSaving: false,
      requirementsCheck: false,
      showSavingDialog: false,
    };
  },
  computed: {
    ...mapGetters([
      'vehicles_found', 
      'companys_found', 
      'types_found', 
      'users_store', 
      'makers_found', 
      'categorys_found',
      'schedules_found',
      'auth_client',
      'auth_user',
      'auth_connected',
      'domain',
      ]),
    vehicle: function() {
      var editVehicle = this.vehicles_found.map[this.id];
      if (!editVehicle) {
        return {};
      }
      editVehicle['_category'] = this.category(editVehicle);
      
      return editVehicle;
    },
    disableAll: function() {
      return this.isSaving;
    },
    data: function() {
      return this.kvps;
    }
  },
  mounted: function() {
    if (!this.auth_connected) {
      this.$router.replace({ name: this.$route.query.redirectTo || ConstUtils.ROUTES.SIGNIN });
    }
  },
  watch: {
    // setup change listeners
    savevehicle_event() {
      if (this.savevehicle_event) {
        if (this.savevehicle_event.error) {
          var error = this.savevehicle_event.error;
          if (error.messageId == EventUtils.SaveVehicle.Errors.NotFound.id) {
            this.error = EventUtils.SaveVehicle.Errors.NotFound;
            this.isSaveDisabled = false;
            this.isSaving = false;
          }
        }
        
        if (this.savevehicle_event.statusCode == EventUtils.CODE.OK) {
          this.$router.replace({ name: this.$route.query.redirectTo || ConstUtils.ROUTES.VEHICLES });
        }
      }
    },
    auth_connected() {
      if (this.auth_connected) {
        this.send()
      }
    },
  },
  methods: {
    isEven: function(index) {
      return StringUtils.isEven(index);
    },
    category: function(vehicle) {
      if (!vehicle) {
        return '';
      }

      if (vehicle.VehicleCategory) {
        var categoryPointer = vehicle.VehicleCategory[ConstUtils.FIELDS.ID];
        var category = this.categorys_found.map[categoryPointer];
        if (!category) {
          category = this.categorys_found.map[vehicle.VehicleCategory];
        }
        if (category) {
          return category[ConstUtils.FIELDS.ID];
        }
      }
      return "";
    },
    schedule: function(vehicle) {
      if (!vehicle) {
        return '';
      }

      var schedulePointer = vehicle.VehicleSchedule[ConstUtils.FIELDS.ID];
      var schedule = this.schedules_found.map[schedulePointer];
      return schedule.number;
    },
    setValue: function(kvp) {
      ContentUtils.set(this.kvps, kvp, this.domain.logger());
      this.isSaveDisabled = false;
      this.message = null;
    },
    done: function() {
      //
    },
    checkRequirements: function() {
      this.requirementsCheck = true;
    },
    save: function (good) {
      if (good) {
        if (this.auth_connected) {
          this.saveAndClose();
        } else {
          this.$connect();  
        }
      } else {
        this.requirementsCheck = false;
      }
    },
    saveAndClose: function() {
      this.closeAfterSaving = true;
      this.send();
    },
    send: function() {
      this.isSaving = true;
      this.error = null;
      this.message = null;
      this.showSavingDialog = true;
      this.kvps[ConstUtils.FIELDS.ID] = this.id;
      this.kvps[ConstUtils.FIELDS.CLASS] = this.vehicle[ConstUtils.FIELDS.CLASS];
      this.event = this.domain.events().vehicleSave(this.kvps);
      this.event.send(this.listener);
    },
    listener: function(event) {
      this.isSaving = false;
      this.isSaveDisabled = false;
      this.showSavingDialog = false;
      if (event.error()) {
        this.error = event.error();
      } else {
        this.message = "Vehicle changes have been successfully saved!";
        let v = new Vehicle(this.domain, this.kvps);
        if (v.isNew() || this.closeAfterSaving) {
          this.$router.replace({ name: this.$route.query.redirectTo || ConstUtils.ROUTES.VEHICLES });
        }
      }
    },
    
    saving: function() {
      this.showSavingDialog = true;
    },
    
    closeSavingDialog: function() {
     this.showSavingDialog = false;  
    },
    
  }  
}
</script>